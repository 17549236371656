import React, { useState } from "react"
import { Link } from "gatsby"
import { gql, useMutation } from "@apollo/client"
import Form from "@rjsf/core"
import { JSONSchema7 } from "json-schema"
import {
  Col,
  Button,
  Container,
  Row,
  Card,
  Modal,
  ModalProps,
  Spinner,
} from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"
import "../style.scss"

import {
  faMapMarkerAlt,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons"

import Kristin from "../assets/img/Kiki.png"
import Ruth from "../assets/img/Ruth.png"
import Praktijk1 from "../assets/img/praktijk1.jpg"
import Praktijk2 from "../assets/img/praktijk2.jpg"
import RouteMap from "../assets/img/route.png"
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3"

export const uiSchema = {
  message: {
    "ui:widget": "textarea",
    "ui:placeholder":
      "Naam en geboortedatum van patiënt, adres, tandarts, telefoonnummer en reden van contact",
  },
  email: {
    "ui:options": {
      inputType: "email",
    },
  },
}
export const schema: JSONSchema7 = {
  title: "",
  type: "object",
  required: ["fname", "name", "email", "message"],
  properties: {
    fname: {
      type: "string",
      title: "Voornaam",
    },
    name: {
      type: "string",
      title: "Achternaam",
    },
    email: {
      type: "string",
      title: "Email",
    },
    message: {
      type: "string",
      title: "Bericht",
    },
    // done: { type: "boolean", title: "Done?", default: false },
  },
}

export const SEND_MAIL = gql`
  mutation SendMailMutation(
    $subject: String!
    $from: String!
    $text: String!
    $token: String!
  ) {
    sendEmail(
      mail: {
        subject: $subject
        captchaTokenV3: $token
        from: $from
        text: $text
      }
    )
  }
`

function RuthModal(props: { onHide: () => void } & ModalProps) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ruth Heidbuchel
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li>Licentiaat Tandheelkunde KU Leuven, 1987-1992</li>
          <li>Fellow KU Nijmegen, 1993-1994</li>
          <li>Gediplomeerde in Gespecialiseerde studies KU Leuven, 1995</li>
          <li>Erkend Specialiste in de Orthodontie KU Leuven, 1994-1998</li>
          <li>
            Lid van Belgische Beroepsvereniging voor Nederlandstalige
            Orthodontisten (BBNO)
          </li>
          <li>Lid van European Orthodontic Society (EOS)</li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={e => {
            e.preventDefault()
            props.onHide()
          }}
        >
          Sluit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function KristinModal(props: { onHide: () => void } & ModalProps) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Kristin Van de Voorde - Heidbuchel
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li>Licentiaat Tandheelkunde KU Leuven, 1983-1988</li>
          <li>Fellow KUNijmegen 1988-1989</li>
          <li>Erkend Specialiste in de Orthodontie KU Leuven, 1989-1993</li>
          <li>
            Doctor in de Medische Wetenschappen KUNijmegen, 1997 (Bilateral
            Cleft Lip and Palate)
          </li>
          <li>Instructor KULeuven, 1993-2000</li>
          <li>Berlijn, tandheelkundige kliniek Steglitz, 2000-2004.</li>
          <li>
            Lid van Belgische Beroepsvereniging voor Nederlandstalige
            Orthodontisten (BBNO)
          </li>
          <li>Lid van European Orthodontic Society (EOS)</li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={e => {
            e.preventDefault()
            props.onHide()
          }}
        >
          Sluit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
interface Response {
  loading: boolean
  error?: any
  submit: (fname: string, name: string, email: string, message: string) => void
  data?: any
}
const useFormSubmitter = () => {
  const [sendEmail, { data, loading, error }] = useMutation(SEND_MAIL)
  const { executeRecaptcha } = useGoogleReCaptcha()
  async function handleSubmit(
    fname: string,
    name: string,
    email: string,
    message: string
  ) {
    if (executeRecaptcha) {
      console.log("Executing recaptcha; should check it on backend!")
      const token = await executeRecaptcha("contact")
      await sendEmail({
        variables: {
          subject: "Contacteer ons",
          text: message,
          from: `${fname} ${name} <${email}>`,
          token,
        },
      })
    } else {
      console.error("Could not use recaptcha")
    }
  }
  if (error) return { loading: false, error, submit: handleSubmit }
  if (loading) return { loading, submit: handleSubmit }
  if (data) {
    return { data: data, submit: handleSubmit, loading: false }
  }

  return {
    loading: false,
    submit: handleSubmit,
  }
}

const ContactForm = () => {
  const { loading, data, error, submit } = useFormSubmitter()
  const [errorTextMail, setErrorTextMail] = useState("")
  let body = (
    <Form
      onSubmit={event => {
        if (event.errors.length > 0) {
          console.error("error in fields")
        }
        const { fname, name, email, message } = event.formData as {
          fname: string
          name: string
          email: string
          message: string
        }
        setErrorTextMail(message)
        submit(fname, name, email, message)
      }}
      schema={schema}
      uiSchema={uiSchema}
    >
      <Button type="submit">Verstuur</Button>
    </Form>
  )
  if (loading)
    body = (
      <div>
        <p>Versturen... Dit kan even duren</p>
        <Spinner animation="border" style={{ color: "orangered" }} />
      </div>
    )

  if (data) {
    body = <p>Bericht verstuurd!</p>
  }
  if (error) {
    body = (
      <div>
        <p>
          Er ging iets mis... Je kan je bericht nog als mail versturen dankzij{" "}
          <a
            href={`mailto:${
              process.env.GATSBY_EMAIL_RECEIVER
            }?subject=Contacteer%20ons&body=${encodeURI(errorTextMail)}`}
          >
            deze link!
          </a>
        </p>
      </div>
    )
  }

  return <div>{body}</div>
}

const ReferenceToContactForm = () => (
  <>
    <br />
    of <Link to={"#leave-message"}>gebruik het contactformulier</Link>
  </>
)
const Contact = () => {
  const [ruthModalShow, setRuthModalShow] = useState(false)
  const [kristinModalShow, setKristinModalShow] = useState(false)
  const [errorTextMail, setErrorTextMail] = useState("")

  return (
    <Layout sectionTitle={"Contact"}>
      <SEO
        title="Contact"
        keywords={[
          "orthodontie",
          "contacteer",
          "contact",
          "praktijk",
          "locatie",
        ]}
      />
      <Container>
        <Row>
          <Col md>
            <h3 className="contact-icon-title">
              <FontAwesomeIcon className="contact-icon" icon={faEnvelope} />
              <span className="ml-1">Email</span>
            </h3>
            <p className="contact-section">
              <a
                href={`mailto:${process.env.GATSBY_EMAIL_RECEIVER}`}
              >{`${process.env.GATSBY_EMAIL_RECEIVER}`}</a>
              <ReferenceToContactForm />
            </p>
          </Col>
          <Col md>
            <h3 className="contact-icon-title">
              <FontAwesomeIcon className="contact-icon" icon={faMapMarkerAlt} />
              <span className="ml-1">Adres</span>
            </h3>
            <p className="contact-section">
              <a href="https://goo.gl/maps/QHeo5Kk2t6aebbEDA">
                Kardinaal Mercierlaan 6, bus 72
                <br />
                3001 Heverlee
              </a>
            </p>
          </Col>
          <Col md>
            <h3 className="contact-icon-title">
              <FontAwesomeIcon className="contact-icon" icon={faPhoneAlt} />
              <span className="ml-1">Telefoon</span>
            </h3>
            <p className="contact-section">
              <a href="tel:+3216236569">+32 (0)16 23 65 69</a>
              <ReferenceToContactForm />
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <noscript>
              <p>
                We cannot provide an interactive map when JavaScript is disabled
              </p>
              <img
                className="noscript-route"
                src={RouteMap} // reference to static folder
                alt={"Routebeschrijving"}
              />
            </noscript>
            <div id="map-container" className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1258.9985296386994!2d4.6962422582227346!3d50.868254187641924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c16124fec55555%3A0xbb4f9d32da54dea4!2sKardinaal%20Mercierlaan%206%2Fbus%2072%2C%203001%20Leuven!5e0!3m2!1snl!2sbe!4v1580573725980!5m2!1snl!2sbe"
                className="google-map-location"
                frameBorder="0"
                allowFullScreen={false}
              />
            </div>
          </Col>
          <Col md={5} className="my-auto list-location-info">
            <ul>
              <li>Makkelijk bereikbaar via Naamsepoort, ring van Leuven</li>
              <li>
                Makkelijk en gratis parkeren in blauwe zone met parkeerschijf
              </li>
              <li>Naast de grote Delhaize</li>
              <li>Bushalte op 30 meter</li>
            </ul>
          </Col>
        </Row>
        <div id="leave-message">
          <h2 className="contact-form-title">Contacteer ons</h2>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
          >
            <ContactForm />
          </GoogleReCaptchaProvider>
        </div>
        <Row className="mx-auto">
          <h2>Team</h2>
        </Row>
        <Row>
          <Col md className="my-auto py-sm-2">
            <Row className="justify-content-center">
              <Card
                className="shadow p-3 mb-5 bg-white rounded"
                style={{ width: "98%" }}
              >
                <Card.Img
                  variant="top"
                  src={Ruth}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "480px",
                  }}
                />
                <Card.Body>
                  <Card.Title>Ruth Heidbuchel</Card.Title>
                  <Button
                    variant="primary"
                    onClick={e => {
                      e.preventDefault()
                      setRuthModalShow(true)
                    }}
                  >
                    Lees meer
                  </Button>
                </Card.Body>
              </Card>
              {/*<img*/}
              {/*  src={Ruth} // reference to static folder*/}
              {/*  alt={"Ruth Heidbuchel"}*/}
              {/*  style={{ objectFit: "cover", width: "100%", height: "480px" }}*/}
              {/*/>*/}
            </Row>
          </Col>
          <Col md className="my-auto py-sm-2">
            <Row className="d-flex justify-content-center">
              <Card
                className="shadow p-3 mb-5 bg-white rounded"
                style={{ width: "98%" }}
              >
                <Card.Img
                  variant="top"
                  className="team-card-img"
                  src={Kristin}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "480px",
                  }}
                />
                <Card.Body>
                  <Card.Title>Kristin Van de Voorde - Heidbuchel</Card.Title>
                  <Button
                    variant="primary"
                    onClick={e => {
                      e.preventDefault()
                      setKristinModalShow(true)
                    }}
                  >
                    Lees meer
                  </Button>
                </Card.Body>
              </Card>
            </Row>
          </Col>
        </Row>
        <Row className="mx-auto">
          <h2>Praktijk</h2>
        </Row>
        <Row>
          <Col md className="my-auto">
            <Row className="justify-content-center">
              <Card
                className="shadow p-3 mb-5 bg-white rounded"
                style={{ width: "98%" }}
              >
                <Card.Img
                  className="praktijk-img"
                  variant="top"
                  src={Praktijk1}
                  alt={"Foto praktijk"}
                />
              </Card>
            </Row>
          </Col>
          <Col md className="my-auto">
            <Row className="justify-content-center">
              <Card
                className="shadow p-3 mb-5 bg-white rounded"
                style={{ width: "98%" }}
              >
                <Card.Img
                  variant="top"
                  src={Praktijk2}
                  alt={"Foto praktijk"}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "auto",
                  }}
                />
              </Card>
            </Row>
          </Col>
        </Row>
      </Container>
      <RuthModal show={ruthModalShow} onHide={() => setRuthModalShow(false)} />
      <KristinModal
        show={kristinModalShow}
        onHide={() => setKristinModalShow(false)}
      />
    </Layout>
  )
}
export default Contact
